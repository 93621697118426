import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import {
  ConnectionServiceFragment,
  ImageFragment,
  StructuredTextFragment,
} from '@codegen/cmsUtils';
import useCmsTranslation from '@hooks/useCmsTranslation';
import StandaloneContentModal from '@ui/components/Content/StandaloneContentModal';
import StructuredTextWrapper from '@ui/components/Content/StructuredTextWrapper';
import useToggle from '@ui/hooks/useToggle';
import { getImageSizes } from '@ui/utils/getImageSizes';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Image from '@ui-v2/core/Image/Image';
import { mqMin } from '@ui-v2/utils/styleUtils';
import { useConstants } from '@web/context/ConstantContext';
import useStandaloneContent from '@web/context/hooks/useStandaloneContent';
import { useSettings } from '@web/context/SettingsContext';

interface ConnectionServiceInfoProps {
  data: ConnectionServiceFragment;
  isSectionTile: Maybe<boolean>;
}

const CONTENT_IDENTIFYER = 'worldwide-connection-protection';

const Container = styled.div<{ isSectionTile: Maybe<boolean> }>(
  ({ isSectionTile, theme: { spacings } }) => [
    css`
      position: relative;
      width: min(100vw, 1440px);
      padding-bottom: ${spacings['24']}px;
      margin-left: calc((100% - min(100vw, 1440px)) / 2);
    `,
    isSectionTile &&
      css`
        width: 100%;
        margin-left: 0;
      `,
  ],
);

const ImageContainer = styled.div(css`
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
`);

const ContentContainer = styled(Box)(
  ({ theme: { sizes, spacings } }) => css`
    position: relative;
    display: flex;
    max-width: ${sizes.container}px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 ${spacings['24']}px;
    margin: auto;
    gap: ${spacings['48']}px;

    ${mqMin[768]} {
      gap: ${spacings['24']}px;
    }

    ${mqMin[960]} {
      flex-direction: row;
    }
  `,
);

const TitleContainer = styled.div<{
  backgroundImage?: Maybe<ImageFragment>;
}>(({ backgroundImage, theme: { spacings } }) => [
  css`
    position: relative;
    display: flex;
    max-width: 375px;
    height: fit-content;
    flex-direction: column;
    padding: 0;
    padding-bottom: ${spacings['48']}px;

    ${mqMin[960]} {
      margin-bottom: ${spacings[24]}px;
    }
  `,
  backgroundImage &&
    css`
      background-image: url(${backgroundImage.url});
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
    `,
]);

const BulletpointsContainer = styled.div<{
  textColor?: Maybe<string>;
}>(({ textColor, theme: { spacings } }) => [
  css`
    display: flex;
    max-width: 100%;
    flex-direction: column;
    padding-bottom: ${spacings['24']}px;
    font-size: 18px;
    font-weight: bold;
    gap: ${spacings['24']}px;

    ${mqMin[960]} {
      max-width: 45%;
      padding-bottom: 0;
      margin-top: ${spacings['24']}px;
      margin-bottom: ${spacings[24]}px;
      gap: ${spacings[20]}px;
    }
  `,
  textColor &&
    css`
      color: ${textColor};
    `,
]);

const TitleBackgroundImage = styled(Image)(css`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
`);

const InfoLogo = styled(Image)(
  ({ theme: { spacings } }) => css`
    width: 200px;
    height: auto;

    ${mqMin[960]} {
      margin-top: ${spacings['24']}px;
    }
  `,
);

const ConnectionServiceInfo = ({
  data,
  isSectionTile,
}: ConnectionServiceInfoProps) => {
  const { locale } = useConstants();
  const { residency } = useSettings();
  const { t } = useCmsTranslation();
  const [showConnectionServiceModal, toggleConnectionServiceModal] =
    useToggle();

  const { content } = useStandaloneContent(CONTENT_IDENTIFYER);

  const { backgroundImage } = data;
  const { titleText } = data;
  const { titleBackgroundImage } = data;
  const { bulletpoints } = data;
  const { titleColor } = data;
  const { bulletpointColor } = data;
  const { infoLogo } = data;

  return (
    <>
      <Container isSectionTile={isSectionTile}>
        <ImageContainer>
          {backgroundImage && (
            <Image
              alt={backgroundImage.alt}
              fill
              objectFit="cover"
              priority
              quality={75}
              sizes={getImageSizes({ Default: '100%' })}
              src={backgroundImage.url}
            />
          )}
        </ImageContainer>
        <ContentContainer>
          {titleText && (
            <TitleContainer>
              <StructuredTextWrapper
                data={titleText.content as Maybe<StructuredText>}
                h2Styles={({ colours, spacings }) => [
                  css`
                    z-index: 1;
                    padding: ${spacings[20]}px;
                    margin: 0;
                    background: ${colours.brand.primary};
                    color: ${titleColor || colours.text.default};
                    font-size: 24px;
                    line-height: 1.2;

                    ${mqMin[480]} {
                      font-size: 28px;
                    }
                  `,
                ]}
              />
              {titleBackgroundImage && (
                <TitleBackgroundImage
                  alt={titleBackgroundImage.alt}
                  objectFit="cover"
                  priority
                  quality={75}
                  sizes={getImageSizes({ Default: '100%' })}
                  src={titleBackgroundImage.url}
                />
              )}
            </TitleContainer>
          )}
          {bulletpoints.length > 0 && (
            <BulletpointsContainer textColor={bulletpointColor}>
              {bulletpoints.map((bulletpoint: StructuredTextFragment) => (
                <StructuredTextWrapper
                  data={bulletpoint.content as Maybe<StructuredText>}
                  key={bulletpoint.id}
                />
              ))}
            </BulletpointsContainer>
          )}
          <Box
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            justifyContent="flex-end"
          >
            {infoLogo && (
              <InfoLogo
                alt={infoLogo.alt}
                objectFit="cover"
                priority
                quality={75}
                sizes={getImageSizes({ Default: '100%' })}
                src={infoLogo.url}
              />
            )}
            <Box ml="auto">
              <Button onClick={toggleConnectionServiceModal}>
                {t('Learn more', 'Learn more')}
              </Button>
            </Box>
          </Box>
        </ContentContainer>
      </Container>
      <StandaloneContentModal
        content={content}
        id={CONTENT_IDENTIFYER}
        isOpen={showConnectionServiceModal}
        locale={locale}
        residency={residency}
        toggleModal={toggleConnectionServiceModal}
      />
    </>
  );
};

export default ConnectionServiceInfo;
